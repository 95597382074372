import React, { FC } from 'react'
import { PageProps } from 'gatsby'

import withMemo from '../../decorators/withMemo'
import { Layout } from '../../components'
import { DisclaimerTemplate } from '../../templates'

const DisclaimerContainer: FC<PageProps> = () => {
  return (
    <Layout>
      <DisclaimerTemplate />
    </Layout>
  )
}

export default withMemo(DisclaimerContainer)
